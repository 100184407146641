import { Message, Loading } from "element-ui"
import store from "../store"
import moment from "moment"

let util = {}

//js日期转换
util.todate = (d) => {
  let obj = new Date(d)
  let month = obj.getMonth() + 1
  month = month < 10 ? "0" + month : month
  let day = obj.getDate()
  let hourse = obj.getHours()
  hourse = hourse < 10 ? "0" + hourse : hourse
  let minute = obj.getMinutes()
  minute = minute < 10 ? "0" + minute : minute
  let second = obj.getSeconds()
  second = second < 10 ? "0" + second : second
  day = day < 10 ? "0" + day : day
  return (
    obj.getFullYear() +
    "-" +
    month +
    "-" +
    day +
    " " +
    hourse +
    ":" +
    minute +
    ":" +
    second
  )
}

util.getCurrentMonth = () => {
  let date = new Date()
  let nowMonth = date.getMonth() + 1
  nowMonth = nowMonth < 10 ? "0" + nowMonth : nowMonth

  return date.getFullYear() + "-" + nowMonth
}

util.getCurrentDate = (d) => {
  let date = new Date(d)
  let month = date.getMonth() + 1
  month = month < 10 ? "0" + month : month
  let day = date.getDate()
  day = day < 10 ? "0" + day : day

  return date.getFullYear() + "-" + month + "-" + day
}

//loading
util.loading = () => {
  const loading = Loading({
    lock: true,
    text: "加载中...",
    spinner: "el-icon-loading",
    background: "rgba(0, 0, 0, 0.7)",
  })
  return loading
}

util.showScore = (score, msg = "") => {
  if (score > 0) {
    Message({
      message: msg + "增加" + score + "积分",
      type: "success",
    })
  }
}

//成功弹窗
util.ok = (msg) => {
  Message({
    message: msg,
    type: "success",
  })
}

//成功弹窗
util.noFinish = () => {
  Message({
    message: "开发中，敬请期待",
    type: "success",
  })
}
//警告弹窗
util.warn = (msg) => {
  Message({
    message: msg,
    type: "warning",
  })
}

//获取当前月第一天
util.getMonthFirstDay = (hasTime = true) => {
  let aData = new Date(),
    year = aData.getFullYear(),
    month = aData.getMonth() + 1,
    firstdate = year + "-" + appendZero(month) + "-01",
    month_first = firstdate
  return hasTime ? month_first + " 00:00:00" : month_first
}

//获取当前月最后一天
util.getMonthLastDay = (hasTime = true) => {
  let lastDay = moment().endOf("month").format("YYYY-MM-DD")
  return hasTime ? lastDay + " 00:00:00" : lastDay
}

//获取当前时间
util.getCurrentDay = (hasTime = true) => {
  let aData = new Date(),
    year = aData.getFullYear(),
    month = appendZero(aData.getMonth() + 1),
    day = appendZero(aData.getDate()),
    hour = appendZero(aData.getHours()),
    minute = appendZero(aData.getMinutes()),
    second = appendZero(aData.getSeconds())
  return hasTime
    ? `${year}-${month}-${day} ${hour}:${minute}:${second}`
    : `${year}-${month}-${day}`
}

//数字小于10的加0
export function appendZero(num) {
  return num < 10 ? "0" + num : num
}

//深度拷贝数组
export function clonArray(actual) {
  const newArray = []
  for (let i = 0; i < actual.length; i++) {
    if (actual[i]) {
      newArray.push(actual[i])
    }
  }
  return newArray
}

//深度拷贝对象
util.deepClone = (source) => {
  if (!source && typeof source !== "object") {
    throw new Error("error arguments", "shallowClone")
  }
  const targetObj = source.constructor === Array ? [] : {}
  Object.keys(source).forEach((keys) => {
    if (source[keys] && typeof source[keys] === "object") {
      targetObj[keys] = util.deepClone(source[keys])
    } else {
      targetObj[keys] = source[keys]
    }
  })
  return targetObj
}

//对象转化成字符串
util.param = (json) => {
  if (!json) return ""
  return clonArray(
    Object.keys(json).map((key) => {
      if (json[key] === undefined || json[key] == null) return ""
      if (Array.isArray(json[key])) {
        let arrParams = ""
        json[key].forEach((val, index) => {
          arrParams += `${key}[]=${val}`
          if (index !== json[key].length - 1) {
            arrParams += "&"
          }
        })
        return arrParams
      }

      return encodeURIComponent(key) + "=" + encodeURIComponent(json[key])
    })
  ).join("&")
}

//文件下载
util.fileDownload = (url, filename = "") => {
  util.ajax(
    url,
    function (xhr) {
      let eleLink = document.createElement("a")
      eleLink.download = filename
      eleLink.style.display = "none"
      let blob = new Blob([xhr.response])
      eleLink.href = URL.createObjectURL(blob)
      document.body.appendChild(eleLink)
      eleLink.click()
      URL.revokeObjectURL(URL.createObjectURL(blob))
      document.body.removeChild(eleLink)
    },
    {
      responseType: "blob",
    }
  )
}

util.ajax = (url, callback, options) => {
  let loadClose = Loading.service({
    background: "transparent",
    text: "下载中...",
    customClass: "createLoading",
  })
  window.URL = window.URL || window.webkitURL
  let xhr = new XMLHttpRequest()
  xhr.open("get", url, true)
  if (options.responseType) {
    xhr.responseType = options.responseType
  }
  let token = store.getters.token
  if (token) xhr.setRequestHeader("X-Token", token)
  xhr.onreadystatechange = function () {
    if (loadClose) loadClose.close()
    if (xhr.readyState === 4 && xhr.status === 200) {
      callback(xhr)
    }
  }
  xhr.send()
}

// 判断是否是企微环境
util.isWorkWechat = () => {
  //获取user-agaent标识头
  var ua = window.navigator.userAgent.toLowerCase()
  //判断ua和微信浏览器的标识头是否匹配
  if (
    ua.match(/micromessenger/i) == "micromessenger" &&
    ua.match(/wxwork/i) == "wxwork"
  ) {
    return true
  } else {
    return false
  }
}

/**
 * 获取当前的年月
 * @returns
 */
util.getCurYearMonth = () => {
  let aData = new Date(),
    year = aData.getFullYear(),
    month = appendZero(aData.getMonth() + 1)
  return `${year}-${month}`
}

/**
 * 获取上个月
 * @returns
 */
util.getBeforeYearMonth = () => {
  let aData = new Date(),
    month = aData.getMonth(),
    year = month === 0 ? aData.getFullYear() - 1 : aData.getFullYear(),
    beforeMonth = month === 0 ? 12 : appendZero(aData.getMonth())
  return `${year}-${beforeMonth}`
}

util.getQueryString = (name) => {
  var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)")
  var r = window.location.search.substr(1).match(reg)
  if (r != null) return unescape(r[2])
  return null
}

util.getUrlParams = (url) => {
  // 通过 ? 分割获取后面的参数字符串
  let urlStr = url.split("?")[1]
  if (typeof urlStr === "undefined") {
    //url无参数
    return {}
  }
  // 创建空对象存储参数
  let obj = {}
  // 再通过 & 将每一个参数单独分割出来
  let paramsArr = urlStr.split("&")
  for (let i = 0, len = paramsArr.length; i < len; i++) {
    // 再通过 = 将每一个参数分割为 key:value 的形式
    let arr = paramsArr[i].split("=")
    obj[arr[0]] = arr[1]
  }
  return obj
}

// 格式化时间 月-日 时-分
util.formatDate = (time) => {
  if (!time) return time;
  return time.slice(5,16);
}

//图片转base64编码，用于excel导出图片

util.imgToBase64 = (url, outputFormat) => {
  return new Promise((resolve, reject) => {
    let canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const img = new Image();
    img.crossOrigin = 'Anonymous'; //解决Canvas.toDataURL 图片跨域问题
    img.onload = () => {
      let width = img.width;
      let height = img.height;

      // 压缩比例 -- 可以自己修改参数。500px宽度以下原尺寸，大于500px比例处理
      let bili = Math.round(width / 500) || 1;
      let rate = 1 / bili;
      canvas.width = width * rate;
      canvas.height = height * rate;
      ctx.drawImage(
        img,
        0,
        0,
        width,
        height,
        0,
        0,
        width * rate,
        height * rate
      );
      let dataURL = canvas.toDataURL(outputFormat || 'image/jpeg');
      // 去除标头 -- 传递给后台时一般去除头部
      // let reg = new RegExp('^data:image/[^;]+;base64,');
      // dataURL = dataURL.replace(reg, '');
      canvas = null;
      resolve(dataURL);
    };
    img.src = url;
  });
}

util.popupNail = (img) =>{
  if (!img) {
    return img;
  }
  if (img.indexOf('oa.cdn.vipxufan.com') > 0) {
    // 腾讯云缩放处理
    return img + '&imageMogr2/thumbnail/400x/';
  } else {
    return img;
  }
}

// 判断变量是否为空
util.isEmpty = (variable) => {
  return variable === "" || variable === null || typeof variable === "undefined" || (Array.isArray(variable) && variable.length === 0);
}

export { util }
